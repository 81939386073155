let appName = process.env.VUE_APP_APPLICATION_NAME;
let context;

export default new Promise(async (resolve) => {
  let plugin = {
    install(app) {
      app.mixin({
        methods: {
          sg(path, defaultValue = undefined, getterPayload) {
            let splitPath = path.split(".");
            let moduleName = splitPath.shift();
            let payload = { path: splitPath.join("."), defaultValue };

            if (!this.$store.hasModule(moduleName)) {
              warn(
                "Generic store getter - module not found. The first part of the path must be the module name.",
                {
                  path,
                  moduleName,
                }
              );
              return defaultValue;
            }
            let specificGetterName = moduleName + "/" + payload.path;

            if (Object.keys(this.$store.getters).includes(specificGetterName)) {
              let val = this.$store.getters[specificGetterName];
              if (typeof val === "function") {
                return val(getterPayload);
              } else {
                return val;
              }
            }
            return this.$store.getters[moduleName + "/generic"](payload);
          },
          ss(path, value) {
            let splitPath = path.split(".");
            let moduleName = splitPath.shift();
            if (!this.$store.hasModule(moduleName)) {
              warn(
                "Generic store getter - module not found. The first part of the path must be the module name.",
                {
                  path,
                  moduleName,
                }
              );
              return false;
            }
            let payload = { path: splitPath.join("."), value };
            return this.$store.commit(moduleName + "/generic", payload);
          },
        },
      });
    },
  };
  resolve(plugin);
});
